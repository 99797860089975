import store from '@/store';

const getScore = {

  getBaseScore() {
    let BaseScore = 0;
    if (store.state.resumesInfo.baseinfo.name !== '小A'
        && store.state.resumesInfo.baseinfo.namecheckedvis === true) {
      BaseScore += 6;
    }
    if (store.state.resumesInfo.baseinfo.imageUrl !== 'https://www.aiwnjl.com/img/profile.jpg'
        && store.state.resumesInfo.baseinfo.imageUrl !== '') {
      BaseScore += 6;
    }
    if (store.state.resumesInfo.baseinfo.telephone !== '12345678901'
        && store.state.resumesInfo.baseinfo.phonecheckedvis === true) {
      BaseScore += 6;
    }
    if (store.state.resumesInfo.baseinfo.email !== '12345678901@qq.com'
        && store.state.resumesInfo.baseinfo.mailcheckedvis === true) {
      BaseScore += 6;
    }
    if (store.state.resumesInfo.baseinfo.objective !== 'xx工程师'
        && store.state.resumesInfo.baseinfo.objectivecheckedvis === true) {
      BaseScore += 5;
    }
    if (store.state.resumesInfo.baseinfo.city !== 'xx城市'
        && store.state.resumesInfo.baseinfo.citycheckedvis === true) {
      BaseScore += 5;
    }

    if (store.state.resumesInfo.educationinfo.eduparas.length >= 1
        && store.state.resumesInfo.switchobj.switchwork === true
        && store.state.resumesInfo.educationinfo.eduparas[0].schoolname !== 'xx大学'
    ) {
      if (store.state.resumesInfo.educationinfo.eduparas[0].schoolexp.length >= 20) {
        BaseScore += 10;
      } else {
        BaseScore += 5;
      }
    }
    if (store.state.resumesInfo.selfevaluationinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchselfeva === true) {
      if (store.state.resumesInfo.selfevaluationinfo.progparas[0].selfevaluationexp.length >= 20) {
        BaseScore += 20;
      } else {
        BaseScore += 10;
      }
    }
    return BaseScore;
  },
  getExperienceScore() {
    let ExperienceScore = 1;
    if (store.state.resumesInfo.workexpinfo.workparas.length >= 1
        && store.state.resumesInfo.switchobj.switchwork === true
        && store.state.resumesInfo.workexpinfo.workparas[0].copname !== '万能简历'
    ) {
      if (store.state.resumesInfo.workexpinfo.workparas[0].workexp.length >= 20) {
        ExperienceScore += 4;
      } else {
        ExperienceScore += 2.5;
      }
    }
    if (store.state.resumesInfo.programsinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchprogram === true
        && store.state.resumesInfo.programsinfo.progparas[0].progname !== 'xx项目'
    ) {
      if (store.state.resumesInfo.programsinfo.progparas[0].progexp.length >= 20) {
        ExperienceScore += 5;
      } else {
        ExperienceScore += 2.5;
      }
    }
    if (store.state.resumesInfo.internshipinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchinternship === true
        && store.state.resumesInfo.internshipinfo.progparas[0].internshipcopname !== 'xx公司'
    ) {
      if (store.state.resumesInfo.internshipinfo.progparas[0].internshipexp.length >= 20) {
        ExperienceScore += 5;
      } else {
        ExperienceScore += 2.5;
      }
    }
    if (store.state.resumesInfo.schoolexpinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchschool === true
        && store.state.resumesInfo.schoolexpinfo.progparas[0].schoolexpname !== 'xx数据建模大赛'
    ) {
      if (store.state.resumesInfo.schoolexpinfo.progparas[0].schoolexp.length >= 20) {
        ExperienceScore += 5;
      } else {
        ExperienceScore += 2.5;
      }
    }
    return ExperienceScore;
  },
  getSkillScore() {
    let SkillScore = 0;
    if (store.state.resumesInfo.schoolexpinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchschool === true) {
      SkillScore += 3.33;
    }
    if (store.state.resumesInfo.specialityinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchspecial === true
    ) {
      SkillScore += 3.33;
    }
    if (store.state.resumesInfo.hobbyinfo.progparas.length >= 1
        && store.state.resumesInfo.switchobj.switchhobby === true
    ) {
      SkillScore += 3.33;
    }
    return SkillScore;
  },
  getBaseScoreTip() {
    const BaseScoreTip = [];
    if (store.state.resumesInfo.baseinfo.name === '小A'
        || store.state.resumesInfo.baseinfo.name === ''
        || store.state.resumesInfo.baseinfo.namecheckedvis === false) {
      BaseScoreTip.push('【基本信息】记得写上自己的姓名哦;');
    }
    if (store.state.resumesInfo.baseinfo.imageUrl === 'https://www.aiwnjl.com/img/profile.jpg'
      || store.state.resumesInfo.baseinfo.imageUrl === ''
      || store.state.resumesInfo.baseinfo.photocheckedvis === false) {
      BaseScoreTip.push('【基本信息】放上一张符合规范的求职照无疑可以加深HR对你的印象；');
    }
    if (store.state.resumesInfo.baseinfo.telephone === '12345678901'
      || store.state.resumesInfo.baseinfo.telephone === ''
      || store.state.resumesInfo.baseinfo.phonecheckedvis === false) {
      BaseScoreTip.push('【基本信息】填写个人电话号码，方便招聘方与你联系；');
    }
    if (store.state.resumesInfo.baseinfo.email === '12345678901@qq.com'
      || store.state.resumesInfo.baseinfo.email === ''
      || store.state.resumesInfo.baseinfo.mailcheckedvis === false) {
      BaseScoreTip.push('【基本信息】填写个人邮箱，接收面试邀请函；');
    }
    if (store.state.resumesInfo.baseinfo.objective === 'xx工程师'
      || store.state.resumesInfo.baseinfo.objective === ''
      || store.state.resumesInfo.baseinfo.objectivecheckedvis === false) {
      BaseScoreTip.push('【求职意向】写明意向岗位很有必要，是否匹配对方的招聘岗位是很重要的评估依据；');
    }
    if (store.state.resumesInfo.baseinfo.city === 'xx城市'
        || store.state.resumesInfo.baseinfo.city === ''
        || store.state.resumesInfo.baseinfo.citycheckedvis === false) {
      BaseScoreTip.push('【求职意向】有时候会出现异地工作的情况，所以写明意向工作城市可以减少不必要的麻烦；');
    }

    if (store.state.resumesInfo.educationinfo.eduparas.length < 1
        || store.state.resumesInfo.switchobj.switchschool === false
        || store.state.resumesInfo.educationinfo.eduparas[0].schoolname === 'xx大学'
    ) {
      BaseScoreTip.push('【教育背景】请填写完整的学校名称/专业名称；');
    }

    if (store.state.resumesInfo.selfevaluationinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchselfeva === false) {
      if (store.state.resumesInfo.selfevaluationinfo.progparas[0].selfevaluationexp.length <= 20) {
        BaseScoreTip.push('【自我评价】自我评价内容还需要继续充实一下哦；');
      } else {
        BaseScoreTip.push('【自我评价】您缺少自我评价，竞争力不足哦！');
      }
    }
    return BaseScoreTip;
  },
  getExperienceScoreTip() {
    const ExperienceScoreTip = [];
    if (store.state.resumesInfo.workexpinfo.workparas.length < 1
        || store.state.resumesInfo.switchobj.switchwork === false
        || store.state.resumesInfo.workexpinfo.workparas[0].copname === '万能简历'
    ) {
      if (store.state.resumesInfo.workexpinfo.workparas[0].workexp.length < 20) {
        ExperienceScoreTip.push('【工作经验】工作经验内容还需要继续充实一下哦；');
      } else {
        ExperienceScoreTip.push('【工作经验】还没有添加工作经验哦！工作经验是招聘方对您岗位匹配度评估的重要依据；');
      }
    }
    if (store.state.resumesInfo.programsinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchprogram === false
        || store.state.resumesInfo.programsinfo.progparas[0].progname === 'xx项目'
    ) {
      if (store.state.resumesInfo.programsinfo.progparas[0].progexp.length < 20) {
        ExperienceScoreTip.push('【项目经验】项目经验内容还需要继续充实一下哦；');
      } else {
        ExperienceScoreTip.push('【项目经验】还没有添加项目经验哦！项目经验是招聘方对您能力的评估的重要依据；');
      }
    }
    if (store.state.resumesInfo.internshipinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchinternship === false
        || store.state.resumesInfo.internshipinfo.progparas[0].internshipcopname === 'xx公司'
    ) {
      if (store.state.resumesInfo.internshipinfo.progparas[0].internshipexp.length < 20) {
        ExperienceScoreTip.push('【实习经历】实习经历内容还需要继续充实一下哦；');
      } else {
        ExperienceScoreTip.push('【实习经历】还没有添加实习经验哦！如不需要该模块，可选择隐藏；');
      }
    }
    if (store.state.resumesInfo.schoolexpinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchschool === false
        || store.state.resumesInfo.schoolexpinfo.progparas[0].schoolexpname !== 'xx数据建模大赛'
    ) {
      if (store.state.resumesInfo.schoolexpinfo.progparas[0].schoolexp.length < 20) {
        ExperienceScoreTip.push('【校园经历】校园经历内容还需要继续充实一下哦；');
      } else {
        ExperienceScoreTip.push('【校园经历】还没有添加校园经历哦！如不需要该模块，可选择隐藏；');
      }
    }
    return ExperienceScoreTip;
  },
  getSkillScoreTip() {
    const SkillScoreTip = [];
    if (store.state.resumesInfo.specialityinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchspecial === false) {
      SkillScoreTip.push('【技能特长】请添加与工作和业务相关的技能特长，如不需要该模块，可选择隐藏；');
    }
    if (store.state.resumesInfo.certificateinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchcertificate === false
    ) {
      SkillScoreTip.push('【荣誉证书】选取含金量高的荣誉奖项按时间倒叙填写，如不需要该模块，可选择隐藏；');
    }
    if (store.state.resumesInfo.hobbyinfo.progparas.length < 1
        || store.state.resumesInfo.switchobj.switchhobby === false
    ) {
      SkillScoreTip.push('【兴趣爱好】还没有添加兴趣爱好哦！如不需要该模块，可选择隐藏；');
    }
    return SkillScoreTip;
  },
};

export default getScore;
