import storageService from '@/service/storageService';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate';

import resumesInfo from '@/store/modules/resumesInfo';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    total: {
      staticimgforbios: 'https://www.aiwnjl.com/img/word_',
      staticimg: 'https://www.aiwnjl.com/img/',
      // bkghref: 'https://www.aiwnjl.com/#/detail-16',
      bkghref: 'https://www.aiwnjl.com/#/detail-16',
      resumehref: 'https://www.aiwnjl.com/#/bioModule-16',
      homehref: 'https://www.aiwnjl.com/#/home',
      markethref: 'https://www.aiwnjl.com/#/market',
      resumeshref: 'https://www.aiwnjl.com/#/resumes',
      abouthref: 'https://www.aiwnjl.com/#/about',
      agreementhref: 'https://www.aiwnjl.com/#/agreement',
      qoshref: 'https://www.aiwnjl.com/#/bioModule-162203',
/*       staticimgforbios: 'https://www.aiwnjl.com/img/word_',
      staticimg: 'https://www.aiwnjl.com/img/',
      // bkghref: 'https://www.aiwnjl.com/#/detail-16',
      bkghref: 'https://localhost:8080/#/detail-16',
      resumehref: 'https://localhost:8080/#/bioModule-16',
      homehref: 'https://localhost:8080/#/home',
      markethref: 'https://localhost:8080/#/market',
      resumeshref: 'https://localhost:8080/#/resumes',
      abouthref: 'https://localhost:8080/#/about',
      agreementhref: 'https://localhost:8080/#/agreement',
      qoshref: 'https://localhost:8080/#/bioModule-162203', */
      baseinfoClick: false,
      editinfoClick: false,
      workinfoClick: false,
      projectinfoClick: false,
      internshipinfoClick: false,
      schoolinfoClick: false,
      specialinfoClick: false,
      certifcateinfoClick: false,
      selfevalinfoClick: false,
      hobbyinfoClick: false,
      otherinfoClick: false,
      region: '',
      value2: '',
      token: storageService.get(storageService.USER_TOKEN),
      userInfo: storageService.get(storageService.USER_INFO)
        ? JSON.parse(storageService.get(storageService.USER_INFO)) : null,
      userResume: storageService.get(storageService.USER_RESUMES)
        ? JSON.parse(storageService.get(storageService.USER_RESUMES)) : null,
      userResumearrayLength: storageService.get(storageService.USER_RESUMES_LEN),
    },
  },
  getters: {
  },
  mutations: {
    changeuserresumesvuex(state, userResume) {
      storageService.set(storageService.USER_RESUMES, JSON.stringify(userResume));
      state.total.userResume = userResume;
    },
    changeresumesarrlenvuex(state, userResumearrayLength) {
      // 更新本地缓存
      storageService.set(storageService.USER_RESUMES_LEN, userResumearrayLength);
      state.total.userResumearrayLength = userResumearrayLength;
    },
    SET_TOKEN(state, token) {
      // 更新本地缓存
      storageService.set(storageService.USER_TOKEN, token);
      // 更新state
      state.total.token = token;
    },
    SET_USERINFO(state, userInfo) {
      // 更新本地缓存
      storageService.set(storageService.USER_INFO, JSON.stringify(userInfo));
      // 更新state
      state.total.userInfo = userInfo;
    },
    // 编辑框弹出
    changebaseinfoClickvuex(state, baseinfoClick) {
      state.total.baseinfoClick = baseinfoClick;
    },
    changeeditinfoClickvuex(state, editinfoClick) {
      state.total.editinfoClick = editinfoClick;
    },
    changeworkinfoClickvuex(state, workinfoClick) {
      state.total.workinfoClick = workinfoClick;
    },
    changeprojectinfoClickvuex(state, projectinfoClick) {
      state.total.projectinfoClick = projectinfoClick;
    },
    changeinternshipinfoClickvuex(state, internshipinfoClick) {
      state.total.internshipinfoClick = internshipinfoClick;
    },
    changeschoolinfoClickvuex(state, schoolinfoClick) {
      state.total.schoolinfoClick = schoolinfoClick;
    },
    changespecialinfoClickvuex(state, specialinfoClick) {
      state.total.specialinfoClick = specialinfoClick;
    },
    changecertifcateinfoClickvuex(state, certifcateinfoClick) {
      state.total.certifcateinfoClick = certifcateinfoClick;
    },
    changeselfevalinfoClickvuex(state, selfevalinfoClick) {
      state.total.selfevalinfoClick = selfevalinfoClick;
    },
    changehobbyinfoClickvuex(state, hobbyinfoClick) {
      state.total.hobbyinfoClick = hobbyinfoClick;
    },
    changeotherinfoClickvuex(state, otherinfoClick) {
      state.total.otherinfoClick = otherinfoClick;
    },
  },
  actions: {
  },
  modules: {
    resumesInfo,
  },
  plugins: [
    createPersistedstate({
      key: 'informations', // 存数据的key名   自定义的  要有语义化
      paths: ['resumesInfo'], // 要把那些模块加入缓存
    }),
  ],
});
